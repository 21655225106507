<template>
    <div class="block steps">
        <div class="block-title">
            <h2>{{ $t("That's how you make it") }}</h2>
        </div>
        <div class="block-sleepmode">
            <p class="sleepmode-switch">Kookstand
                <input type="checkbox" @change="onCokestand" :checked="isSleepModeActive">
            </p>
        </div>
        <div class="block-sleepmode">
            <p><span class="sleepmode-tip">Tip:</span><span class="sleepmode-message">Zet de kookstand aan zodat je scherm niet in slaaptstand gaat tijdens het koken.</span></p>
        </div>
        <div class="block-content">
            <div class="step" v-for="(step, index) in steps" :key="`step/${index}`">
                <dt><label>Stap {{ index + 1 }}</label></dt>
                <dd>
                    <cms-body
                        :html="step" />
                </dd>
            </div>
        </div>
    </div>
</template>

<script>
import CmsBody from "@/components/CmsBody";
import NoSleep from "nosleep.js";

export default {
    name: "RecipeSteps",
    props: {
        steps: Array
    },
    components: {
        CmsBody
    },
    data() {
        return {
            noSleep: null,
            isSleepModeActive: false
        };
    },
    mounted() {
        this.initializeNoSleep();
    },
    beforeUnmount() {
        this.cleanupNoSleep();
    },
    methods: {
        initializeNoSleep() {
            this.noSleep = new NoSleep();

            const savedState = JSON.parse(localStorage.getItem("isSleepModeActive"));
            this.isSleepModeActive = savedState === true;

            if (this.isSleepModeActive) {
                this.noSleep.enable();
            }
        },
        cleanupNoSleep() {
            if (this.noSleep) {
                this.noSleep.disable();
                this.noSleep = null;
            }
        },
        onCokestand(e) {
            this.isSleepModeActive = e.currentTarget.checked;

            localStorage.setItem("isSleepModeActive", JSON.stringify(this.isSleepModeActive));

            if (this.isSleepModeActive) {
                this.noSleep.enable();
            } else {
                this.noSleep.disable();
            }
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
